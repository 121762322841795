import { Routes, Route, useLocation } from "react-router-dom";
import useGeoLocation from "react-ipgeolocation";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import ProductList from "./components/ProductList";
import ProductDetails from "./components/ProductDetails";
import Cart from "./components/Cart";
import Home from "./components/Home";
import Checkout from "./components/Checkout";
import SignIn from "./components/signInSignUp/SignIn";
import SignUp from "./components/signInSignUp/SignUp";
import Profile from "./components/Profile.js";
import HowTo from "./components/HowTo.js";
import FaqPage from "./components/FaqPage.js";
import ProtectedRoute from "./utils/ProtectedRoute";
import Addresses from "./components/Addresses";
import Order from "./components/Order";
import { useEffect, useLayoutEffect } from "react";
import { getCart } from "./features/apiCall";
import { useDispatch, useSelector } from "react-redux";
import AllOrders from "./components/AllOrders";
import NotAllowed from "./utils/NotAllowed";
import AddReview from "./components/AddReview";
import { AnimatePresence } from "framer-motion";
import NotFound from "./components/NotFound";
import CheckoutAddr from "./components/CheckoutAddr";
import MyCoupons from "./components/MyCoupons";

function App() {
  const dispatch = useDispatch();
  const pageLocation = useLocation();
  // const cls = window.location.pathname === '/' ? "h-dark" : "h-light";
  const cls = "h-light";
  const location = useGeoLocation();

  const { ageCheck } = useSelector((state) => state.ageCheck);
  const { token } = useSelector((state) => state.auth);

  return (
    <>
      {/* {location.country !== "CA" ? (
        <NotAllowed />
      ) : ( */}

      <>
        {(ageCheck || token) && <Header />}
        <AnimatePresence mode={"wait"}>
          <Routes location={pageLocation} key={pageLocation.pathname}>
            <Route
              path="/"
              element={
                <>
                  <Home />
                </>
              }
            />
            <Route
              path="/shop/:id"
              element={
                <>
                  <ProductList />
                </>
              }
            />

            <Route
              path="/home/:id"
              element={
                <>
                  <ProductDetails />
                </>
              }
            />
            <Route
              path="/home/cart"
              element={
                <ProtectedRoute>
                  <Cart />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/checkout"
              element={
                <ProtectedRoute>
                  <Checkout />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/checkout-address"
              element={
                <ProtectedRoute>
                  <CheckoutAddr />
                </ProtectedRoute>
              }
            />
            <Route path="/home/sign-in" element={<SignIn />} />
            <Route path="/home/sign-up" element={<SignUp />} />
            <Route
              path="/home/my-account"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/my-address"
              element={
                <ProtectedRoute>
                  <Addresses />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/my-coupons"
              element={
                <ProtectedRoute>
                  <MyCoupons />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/order"
              element={
                <ProtectedRoute>
                  <Order />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home/my-orders"
              element={
                <ProtectedRoute>
                  <AllOrders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/review/review-your-purchase/:id"
              element={
                <ProtectedRoute>
                  <AddReview />
                </ProtectedRoute>
              }
            />
            <Route path="/home/how-to" element={<HowTo />} />
            <Route path="/home/faq" element={<FaqPage />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/not-found" element={<NotFound />} />

            <Route path="/restricted" element={<NotAllowed />} />
          </Routes>
        </AnimatePresence>
        {(ageCheck || token) && <Footer />}
      </>

      {/* )} */}
    </>
  );
}

export default App;
