import React, { useEffect, useState } from "react";
import CustomCarousel from "./carousel/Carousel";
import { Col, Row, Card, Button, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ReactCard from "./card/Card";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useGetAllCategoriesQuery } from "../features/productsApi";
import axios from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { addCart, buyAgain, getCart } from "../features/apiCall";
import { motion } from "framer-motion";
import {
  promotionFailure,
  promotionStart,
  promotionSuccess,
} from "../features/promotionsSlice";
import {
  ordersFailure,
  ordersStart,
  ordersSuccess,
} from "../features/ordersSlice";
import {
  buyAgainFailure,
  buyAgainStart,
  buyAgainSuccess,
} from "../features/buyAgain";
import ModalLayout from "./modal/ModalLayout";
import { ageCheckSuccess } from "../features/ageCheckSlice";
import AlertBox from "./layout/AlertBox";

const Home = () => {
  const [categories, setCategories] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const { promotions, loadingPromotion, promotionsErr } = useSelector(
    (state) => state.promotions
  );
  const { orders, loadingOrders, ordersErr } = useSelector(
    (state) => state.orders
  );
  const { buyAgainLoading, buyAgainErr } = useSelector(
    (state) => state.buyAgain
  );
  const { ageCheck } = useSelector((state) => state.ageCheck);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !ageCheck) {
      setModal(true);
    }
  }, [token, ageCheck]);

  useEffect(() => {
    const getCartDetails = async () => {
      await getCart(dispatch);
    };

    if (token) {
      getCartDetails();
    }
  }, [dispatch, token, ageCheck]);

  const { data: categoryData, isLoading: categoryLoading } =
    useGetAllCategoriesQuery();

  const fetchOrders = async () => {
    dispatch(ordersStart());

    try {
      const { data } = await axios.get("/api/order/recent-order", {
        headers: { Authorization: `${token}` },
      });

      // setOrders(data?.orders);
      dispatch(ordersSuccess(data?.orders));
    } catch (error) {
      dispatch(ordersFailure(error?.response?.data?.error?.message));
    }
  };

  const carouselDetails = async () => {
    dispatch(promotionStart());

    try {
      const { data } = await axios.get("/api/promotion/all", {
        headers: { Authorization: `${token}` },
      });

      // console.log("carousel ", data);
      // setPromotions(data?.promotions);
      dispatch(promotionSuccess(data?.promotions));
    } catch (error) {
      dispatch(promotionFailure(error?.response?.data));
    }
  };

  
  const toastOptions = {
    position: "top-right",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  const handleReOrder = async (orderId, product, quantity) => {
    dispatch(buyAgainStart());

    try {
      const { data } = await axios.post("/api/cart/recent-cart",
        { orderId },
        { headers: { Authorization: `${token}` }, }
      );

      getCart(dispatch);
      dispatch(buyAgainSuccess(data));

      navigate("/home/cart");
    } catch (error) {
      toast.error(error?.response?.data?.error?.message, toastOptions);
      dispatch(buyAgainFailure(error?.response?.data?.error?.message));
    }

   
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (categoryData) {
      setCategories(categoryData?.categories);
    }

    if (token) {
      fetchOrders();
    }

    carouselDetails();
  }, [categoryData, token, ageCheck]);

  return (
    <>
      {(ageCheck || token) && (
        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.75, ease: "easeInOut" }}
        >
          <ReactPlaceholder
            type="text"
            color="#F0F0F0"
            showLoadingAnimation
            rows={5}
            style={{ width: "60%", margin: "auto" }}
            ready={!loadingPromotion}
          >
            <CustomCarousel promotions={promotions} />
          </ReactPlaceholder>
          <section className="sec-1">
            <div className="sec-1-heading">
              <h1 className="h-heading">Shop Categories</h1>
            </div>
            <div className="sec-1-body">
              <Row className="m-0 gap-2 justify-content-center align-items-center">
                <ReactPlaceholder
                  type="text"
                  color="#F0F0F0"
                  showLoadingAnimation
                  rows={5}
                  ready={!categoryLoading}
                >
                  {categories?.length <= 0 ? (
                    <AlertBox
                      type={"danger"}
                      heading={"Something went wrong!"}
                      desc={"We are working on resolving the issue."}
                      onHome={true}
                    />
                  ) : (
                    categories?.map((item) => (
                      <Col lg={2} md={4} key={item._id}>
                        <ReactCard item={item} />
                      </Col>
                    ))
                  )}
                </ReactPlaceholder>
              </Row>
            </div>
          </section>
          <section className="sec-1 ">
            <div className="sec-recent-order">
              <div className="sec-1-heading">
                <h1 className="h-heading">Recent Orders</h1>
              </div>
              <ReactPlaceholder
                type="text"
                color="#fff"
                showLoadingAnimation
                rows={5}
                ready={!loadingOrders && !categoryLoading}
              >
                <Row className="m-0 gap-3 justify-content-center">
                  {orders?.length !== 0 && token
                    ? orders?.map((item) => (
                        <div className="orders-card-div" key={item?._id}>
                          <Card className="order-card">
                            <Card.Body
                              style={{ width: "100%" }}
                              key={Math.random()}
                            >
                              <div className="recent-order-heading">
                                <p>Product</p>
                                <p>Subtotal</p>
                              </div>
                              {item?.products?.length >= 3
                                ? item?.products
                                    ?.filter((item, i) => i < 2)
                                    .map((product) => (
                                      <>
                                        <Card.Text
                                          className="order-text"
                                          key={product?.product?._id}
                                        >
                                          <div className="order-details-div">
                                            <div className="recent-order-info">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  // alignItems: "center",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div className="recent-order-img">
                                                    <img
                                                      src={
                                                        product?.parent_prod
                                                          ?.product_images[0]
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <p
                                                    style={{
                                                      fontSize: "0.9rem",
                                                    }}
                                                  >
                                                    {product?.parent_prod?.name}
                                                    <div>
                                                      <p className="order-quantity">
                                                        x {product?.quantity}
                                                      </p>
                                                    </div>
                                                  </p>
                                                </div>

                                                <span
                                                  className="prod-qname"
                                                  style={{
                                                    padding: "0rem 0.7rem",
                                                  }}
                                                >
                                                  {product?.product?.qname}
                                                </span>
                                              </div>
                                            </div>
                                            {product?.parent_prod?.sale > 0 ? (
                                              <div
                                                style={{
                                                  paddingRight: "0.5rem",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "flex-end",
                                                }}
                                              >
                                                <p className="order-old-price">
                                                  $
                                                  {(
                                                    product?.product?.amount *
                                                    product?.quantity
                                                  )?.toFixed(2)}
                                                </p>

                                                <p className="order-updated-price">
                                                  $
                                                  {(
                                                    product?.updatedAmount *
                                                    product?.quantity
                                                  )?.toFixed(2)}
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  paddingRight: "0.5rem",
                                                }}
                                              >
                                                $
                                                {(
                                                  product?.product?.amount *
                                                  product?.quantity
                                                )?.toFixed(2)}
                                              </div>
                                            )}
                                          </div>
                                        </Card.Text>
                                      </>
                                    ))
                                : item?.products?.map((product) => (
                                    <>
                                      <Card.Text
                                        className="order-text"
                                        key={product?.product?._id}
                                      >
                                        <div className="order-details-div">
                                          <div className="recent-order-info">
                                            <div
                                              style={{
                                                display: "flex",
                                                // alignItems: "center",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div className="recent-order-img">
                                                  <img
                                                    src={
                                                      product?.parent_prod
                                                        ?.product_images[0]
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <p
                                                  style={{ fontSize: "0.9rem" }}
                                                >
                                                  {product?.parent_prod?.name}
                                                  <div>
                                                    <p className="order-quantity">
                                                      x {product?.quantity}
                                                    </p>
                                                  </div>
                                                </p>
                                              </div>

                                              <span
                                                className="prod-qname"
                                                style={{
                                                  padding: "0rem 0.7rem",
                                                }}
                                              >
                                                {product?.product?.qname}
                                              </span>
                                            </div>
                                          </div>
                                          {product?.parent_prod?.sale > 0 ? (
                                            <div
                                              style={{
                                                paddingRight: "0.5rem",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              <p className="order-old-price">
                                                $
                                                {(
                                                  product?.product?.amount *
                                                  product?.quantity
                                                )?.toFixed(2)}
                                              </p>

                                              <p className="order-updated-price">
                                                $
                                                {(
                                                  product?.updatedAmount *
                                                  product?.quantity
                                                )?.toFixed(2)}
                                              </p>
                                            </div>
                                          ) : (
                                            <div
                                              style={{ paddingRight: "0.5rem" }}
                                            >
                                              $
                                              {(
                                                product?.product?.amount *
                                                product?.quantity
                                              )?.toFixed(2)}
                                            </div>
                                          )}
                                        </div>
                                      </Card.Text>
                                    </>
                                  ))}
                              {/* {item?.products?.length >= 3 && "..."} */}
                              <hr style={{ opacity: "0.13" }} />
                              <div className="home-subtotal">
                                <p className="home-subtotal-txt">Subtotal</p>
                                <p className="home-subtotal-txt">
                                  $
                                  {(item?.free_ship
                                    ? item?.amount
                                    : item?.amount - item?.shipping_charge
                                  ).toFixed(2)}
                                </p>
                              </div>
                              <div className="home-subtotal">
                                {item?.free_ship ? (
                                  <div
                                    style={{ width: "100%" }}
                                    className="d-flex flex-column"
                                  >
                                    <div className="d-flex justify-content-between">
                                      <p
                                        style={{
                                          fontWeight: 700,
                                          textDecoration:
                                            item?.free_ship && "line-through",
                                        }}
                                      >
                                        Shipping:
                                      </p>
                                      <p
                                        style={{
                                          textDecoration:
                                            item?.free_ship && "line-through",
                                        }}
                                      >
                                        $ {item?.shipping_charge}
                                      </p>
                                    </div>

                                    <div
                                      className="d-flex justify-content-between mt-2"
                                      style={{ fontSize: "0.9rem" }}
                                    >
                                      <strong className="home-shipping">
                                        Free Shipping Applied
                                      </strong>
                                      <strong className="home-shipping">
                                        $ 0
                                      </strong>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <p className="home-shipping">Shipping</p>
                                    <p className="home-shipping">
                                      ${(item?.shipping_charge).toFixed(2)}
                                    </p>
                                  </>
                                )}
                              </div>
                              <hr style={{ opacity: "0.13" }} />
                              <div className="recent-order-total">
                                <i>${item?.amount?.toFixed(2)}</i>
                              </div>
                            </Card.Body>
                            {item?.products?.length >= 3 && (
                              <div className="home-view-order">
                                <Link
                                  to="/home/my-orders"
                                  className="home-view-order-link"
                                >
                                  View full order
                                </Link>
                              </div>
                            )}
                            <div className="ordered-at">
                              <i>
                                - Ordered on{" "}
                                {moment(item?.createdAt)
                                  .utc()
                                  .format("MMMM DD, YYYY")}
                              </i>
                            </div>
                            <hr />
                            <div className="re-order-btn">
                              {buyAgainLoading ? (
                                <Button variant="dark" >
                                  <p>Buy again</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    class="w-5 h-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </Button>
                              ) : (
                                <Button
                                  variant="dark"
                                  onClick={() => handleReOrder(item?._id)}
                                  // onClick={() => console.log(item?._id)}
                                >
                                  <p>Buy again</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    class="w-5 h-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </Button>
                              )}
                            </div>
                          </Card>
                        </div>
                      ))
                    : !token && (
                        <div className="no-recent">
                          <div className="no-recent-conta">
                            <p>
                              Create an account or login to get custom
                              promotions on your favourite products!
                            </p>
                            <div className="no-recent-btn">
                              <Button
                                variant="dark"
                                onClick={() => navigate("/home/sign-in")}
                              >
                                Sign In
                              </Button>
                              <Button
                                variant="dark"
                                onClick={() => navigate("/home/sign-up")}
                              >
                                Sign Up
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                  {orders?.length === 0 && token && (
                    <div>
                      <AlertBox
                        type={"dark"}
                        heading={"No recent orders!"}
                        desc={
                          <>
                            <p className="mb-4 mt-3">
                              Your most recent orders will appear here.
                            </p>
                          </>
                        }
                        onHome={true}
                        fullWidth={true}
                      />
                      {/* <Alert
                        variant="dark"
                        style={{ margin: "auto" }}
                        className="no-prod-msg-box-alert"
                      >
                        <Alert.Heading className="no-prod-msg-box">
                          <span>No recent orders!</span>
                        </Alert.Heading>

                        <hr />
                        <p className="mb-4 mt-3">
                          Your most recent orders will appear here.
                        </p>
                        <hr />
                      </Alert> */}
                    </div>
                  )}
                </Row>
              </ReactPlaceholder>
             
            </div>
          </section>
        </motion.div>
      )}

      {modal && (
        <ModalLayout
          status={"ageCheck"}
          backdrop={"static"}
          show={modal}
          scrollable={"false"}
          handleClose={() => {
            dispatch(ageCheckSuccess(false));
            navigate("/restricted");
          }}
          handleCloseAge={() => {
            dispatch(ageCheckSuccess(true));
            setModal(!modal);
          }}
        />
      )}
       <ToastContainer />
    </>
  );
};

export default Home;
