import React, { useEffect, useState } from "react";
import { Container, Row, Form, Col, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { register } from "../../features/apiCall";
import { motion } from "framer-motion";
import { ageCheckSuccess } from "../../features/ageCheckSlice";
import ModalLayout from "../modal/ModalLayout";

const SignUp = () => {
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile_no: "",
    password: "",
    reference: "",
  });
  const { ageCheck } = useSelector((state) => state.ageCheck);
  const [modal, setModal] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [ageChecked, setAgeChecked] = useState(false);
  const { isFetching, errMsg, error, token } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    if (!token && !ageCheck) {
      setModal(true);
    }
  }, [token, ageCheck]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (localStorage.getItem("userToken")) {
      navigate("/");
    }
  }, [navigate, token]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstname, lastname, email, password, mobile_no, reference } =
      values;

    const refer_code = reference;

    if (mobile_no?.length < 10) {
      toast.error("Mobile number must be atleast 10 digits!", toastOptions);
      return;
    }

    if (lastname?.length > 4 && firstname?.length > 4) {
      register(dispatch, {
        firstname,
        lastname,
        email,
        password,
        mobile_no,
        refer_code,
      });

      setIsRegistered(true);
    } else {
      toast.error(
        "Firstname and lastname must have more than 4 characters!",
        toastOptions
      );
    }
  };

  if (error && !isFetching && isRegistered) {
    toast.error(errMsg.message, toastOptions);

    setIsRegistered(false);
  }

  return (
    <>
      {(ageCheck || token) && (
        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.75, ease: "easeInOut" }}
        >
          <Container className="pt-5 px-3 px-md-0 f-center flex-column">
            <div className="f-center mb-4">
              <Link to="/home/sign-in" className="toggle-link-item">
                Login
              </Link>
              <Link to="/home/sign-up" className="toggle-link-item active-link">
                Register
              </Link>
            </div>
            <div className="form-box">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="firstname"
                        placeholder="First Name"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="lastname"
                        placeholder="Last Name"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    name="mobile_no"
                    placeholder="Mobile No."
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="reference"
                    placeholder="Referal code"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    onChange={(e) => setAgeChecked(e.target.checked)}
                    value={ageChecked}
                    type={"checkbox"}
                    label={`I am 19+ years old`}
                    required
                  />
                </Form.Group>

                {isFetching ? (
                  <Button variant="dark" size="lg" disabled>
                    <Spinner animation="border" variant="light" />
                  </Button>
                ) : (
                  <Button type="submit" variant="dark" disabled={!ageChecked}>
                    Submit
                  </Button>
                )}
              </Form>
            </div>
          </Container>
        </motion.div>
      )}

      {modal && (
        <ModalLayout
          status={"ageCheck"}
          backdrop={"static"}
          show={modal}
          scrollable={"false"}
          handleClose={() => {
            dispatch(ageCheckSuccess(false));
            navigate("/restricted");
          }}
          handleCloseAge={() => {
            dispatch(ageCheckSuccess(true));
            setModal(!modal);
          }}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default SignUp;
